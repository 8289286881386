@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/variables';
@use '@/styles/resources/z-index' as zIndexes;

.root {
    -webkit-appearance: none;
    background-color: variables.$modal-mask-background-color;
    cursor: auto;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: zIndexes.$index--modal;
    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        -webkit-backdrop-filter: blur(0.2rem);
        backdrop-filter: blur(0.2rem);
    }
}

.root_active {
    composes: root;
    opacity: 1;
    visibility: visible;
}
