@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/z-index' as zIndexes;

.demoNotice {
    background-color: colors.$brand-color--tertiary;
    color: colors.$color--white;
    font-size: 0.75rem;
    padding: 0.3rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    z-index: zIndexes.$index--header;

    &OpenSearch {
        display: none;
    }
}
