@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
.shippingTitle {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;

    & svg {
        transform: rotate(90deg);
        transition: transform 0.16s ease-in-out;
    }
}
