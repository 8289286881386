@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

.filterItem {
    padding: 0.75rem 1rem;

    @include vars.mq-medium-up {
        padding: 0.5rem 1rem;
    }

    &:hover {
        background-color: var(--color--gray-200);
    }

    &Color {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.6rem 0;
        text-align: center;

        > span {
            margin-right: 0;
        }

        @include vars.mq-medium-up {
            padding: 0.5rem 0;

            &:hover {
                background-color: unset;
            }
        }

        &:hover {
            background-color: unset;
        }
    }

    &Size {
        display: flex;
    }
}
