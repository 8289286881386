@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';

.container {
    color: colors.$text-color--primary;
    display: grid;
    justify-items: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.title {
    @include mixins.heading-5();
    margin-bottom: 1rem;
}

.icon {
    height: 1.2rem;
}

.connectionsContainer {
    display: flex;
}

.connect {
    align-items: center;
    border: none;
    border-radius: 50%;
    display: flex;
    height: 3rem;
    justify-content: center;
    margin: 0 1rem;
    transition: linear 0.3s;
    width: 3rem;
}

.connectFacebook {
    composes: connect;
    background: #3b5998;
    color: #fff;

    &:hover {
        background: #314a7e;
        border: 0.1rem solid #314a7e;
    }
}

.connectGoogle {
    composes: connect;
    background: #dd4b39;
    color: #fff;

    &:hover {
        background: #cf3624;
        border: 0.1rem solid #cf3624;
    }
}
