@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';

.buttonWrapper {
    margin-top: 2rem;
}

.checkoutButton {
    font-size: 1rem;
}

.totalsWrapper {
    color: colors.$text-color--gray;
    display: grid;
    font-size: 0.875rem;
    grid-auto-columns: min-content;
    grid-gap: 0.5rem 0;
    grid-template-columns: 1.2fr 0.8fr;
    margin-bottom: 1rem;
}

.totalsPrice {
    text-align: right;
}

.totalsPriceSaleLabel {
    align-items: center;
    display: inline-flex;
    gap: 0.5rem;
}

.totalsPriceSale,
.totalsPriceSaleSummary {
    color: colors.$color--free-speech-red;
    text-align: right;
    white-space: nowrap;
}

.totalsPriceOrder {
    border-top: 1px solid colors.$border-color--tertiary;
    font-size: 1rem;
    font-weight: vars.$font-weight--medium;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    text-transform: capitalize;
}

.totalsVatText {
    text-transform: none;
}

.totalsPriceCoupon {
    display: flex;
}

.totalsPriceCouponLabel {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.priceSkeletonLine {
    display: flex;
    gap: 0.5rem;

    > span {
        &:first-of-type {
            width: 100%;
        }
    }
}

.discountBox {
    grid-column: 1 / span 2;
}

.discountBoxOpen {
    composes: discountBox;

    .discountBoxIcon {
        transform: rotate(180deg);
    }

    .totalsPriceSaleSummary {
        display: none;
    }
}

.discountBoxButton {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:focus {
        outline: none;
    }
}

.discountBoxIcon {
    transition: transform 0.2s;
}

.discountBoxContent {
    display: grid;
    gap: 0.5rem;
    padding: 0.5rem 0 0 1rem;
}

.discountBoxRow {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
}

.tooltipTrigger {
    align-items: center;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(2, auto) 1fr;
    width: 100%;

    > svg {
        stroke: colors.$tooltip-text-color--info;
    }
}

.tooltipContainer {
    grid-column: 1 / span 2;
}

.price {
    order: 1;
    text-align: right;
}

.paypalCart iframe {
    margin-top: 1.5rem;
}

.klarnaCart {
    margin-top: 1.5rem;
}
