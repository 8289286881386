@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use './utils';

.errorMessage {
    color: var(--color--system-error-500, colors.$message-text--error);
}

.productRow {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr minmax(auto, 6rem) minmax(auto, 8rem) 1fr;
    padding: 1rem 0.3rem;
    position: relative;

    @include responsive.media('>=tablet') {
        gap: 0.75rem;
        grid-template-columns: utils.$desktopRowTemplate;
        padding: 1rem 0.75rem;
    }

    + .productRow {
        border-top: 1px solid colors.$border-color--tertiary;
    }

    &Disabled {
        &::after {
            backdrop-filter: blur(0.2rem);
            background-color: rgba(255, 255, 255, 0.5);
            bottom: 0;
            content: '';
            left: -0.1rem;
            position: absolute;
            right: -0.1rem;
            top: 0;
        }
    }
}

.cell {
    align-items: center;
    display: flex;
    justify-content: left;
}

.productCellInfo {
    composes: cell;

    @include responsive.media('<tablet') {
        grid-column: 1/5;
    }
}

.productCellQty {
    composes: cell;
    justify-content: center;

    @include responsive.media('<tablet') {
        grid-column: 1/2;
    }
}

.productCellPrice {
    composes: cell;
    justify-content: center;

    @include responsive.media('<tablet') {
        display: none;
    }
}

.productCellAction {
    composes: cell;
    justify-content: center;

    @include responsive.media('<tablet') {
        grid-column: 2 / 4;
        justify-content: space-between;
    }
}

.productCellRemoveButton {
    composes: cell;
    @include responsive.media('<tablet') {
        justify-content: right;
    }
}

.imageRoot {
    height: 100%;
}

.image {
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.imageWrapper {
    display: flex;
    height: 4rem;
    width: 4rem;
}

.productInfo {
    align-items: center;
    display: grid;
    font-size: 0.75rem;
    grid-gap: 0.75rem;
    grid-template-columns: 4rem 1fr;
}

.productName {
    display: block;
    font-weight: vars.$font-weight--bold;
    margin-bottom: 0.25rem;
    text-transform: none;
}

.productNameLink {
    @include mixins.link-underline(colors.$text-color--primary, colors.$text-color--primary);
}

.price {
    font-size: 0.9rem;

    @include responsive.media('>=tablet') {
        font-size: 1rem;
    }
}

.priceBox {
    composes: price;
}

.priceBoxUpdating {
    composes: priceBox;
}

.priceWrapperMobile {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    margin-bottom: 0.25rem;

    @include responsive.media('>=tablet') {
        display: none;
    }
}

.productPrice {
    justify-content: center;
}

.productPriceMobile {
    font-weight: vars.$font-weight--medium;
}

.stockStatus {
    color: colors.$text-color--red;
    font-weight: vars.$font-weight--medium;
    text-transform: uppercase;
}
