@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';

.root {
}

.errorMessage {
    color: colors.$message-text--error;
    font-size: 0.75rem;
    font-weight: fonts.$font-weight--regular;
    line-height: 1.5;
}
